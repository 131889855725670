import 'jquery';
import 'bootstrap-sass';
import 'lodash';
import 'jquery-ui-dist/jquery-ui';
import 'moment';
import 'fullcalendar';
import 'fullcalendar/dist/gcal';
import 'pickadate/lib/picker';
import 'pickadate/lib/legacy';
import 'pickadate/lib/picker.time';
import 'pickadate/lib/picker.date';
import 'angular';
import '@uirouter/angularjs';
import 'restangular';
import 'ng-pickadate';
import 'ng-file-upload';
import 'textangular/dist/textAngular-sanitize';
import 'textangular';
import 'angular-animate';
import 'angular-messages';
import 'angular-loading-bar';
import 'angular-ui-bootstrap';
import 'angular-ui-mask';
import 'angular-ui-calendar';
import 'angular-color-picker';
import '@cgross/angular-notify';
import 'angulartics';
import 'angulartics-google-tag-manager';
import 'angular-local-storage';
import 'angular-ui-sortable';
